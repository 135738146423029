<template>
  <transition name="modal" appear>
    <div v-if="video" class="modal-video-mask" role="dialog">
      <div class="modal-video-container">
        <video class="modal-video" :src="video" autoplay controls />
        <button class="btn-close" @click="close">
          <ppicon name="close" alt="close" />
        </button>
      </div>
    </div>

    <div v-else class="modal-mask" role="dialog">
      <div class="modal-container" :class="`modal-${size}`">
        <button class="btn-close" @click="close">
          <ppicon name="close" alt="close" />
        </button>

        <div class="modal-header">
          <div class="modal-title">
            <slot name="header">
              {{ title }}
            </slot>
          </div>
          <div v-if="title || $slots.header" class="modal-divider" />
          <div v-if="subtitle" class="modal-subtitle">
            <slot name="subtitle">
              {{ subtitle }}
            </slot>
          </div>
        </div>

        <Scrollbar class="modal-body">
          <div class="body-slot"><slot></slot></div>
        </Scrollbar>

        <div class="modal-footer" v-if="!hideFooter">
          <slot name="footer">
            <div class="button-container">
              <slot name="buttons">
                <slot name="cancel">
                  <button
                    class="btn btn-secondary"
                    @click="close"
                    v-if="showCancelButton">
                    {{ cancelButtonText }}
                  </button>
                </slot>

                <slot name="submit">
                  <button class="btn" @click="submit" v-if="showSubmitButton">
                    {{ submitButtonText }}
                  </button>
                </slot>
              </slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Scrollbar from "./Scrollbar.vue";
  export default {
    components: { Scrollbar },
    props: {
      video: {
        type: String,
      },

      size: {
        type: String,
        default: "md",
      },

      title: {
        type: String,
      },

      subtitle: {
        type: String,
      },

      hideFooter: {
        type: Boolean,
        default: false,
      },

      cancelButtonText: {
        type: String,
        default() {
          return this.$lang.general_Cancel;
        },
      },

      showCancelButton: {
        type: Boolean,
        default: true,
      },

      submitButtonText: {
        type: String,
        default() {
          return this.$lang.modal_Submit;
        },
      },

      showSubmitButton: {
        type: Boolean,
        default: true,
      },
    },

    created() {
      document.body.classList.add("modal-open");
    },

    destroyed() {
      document.body.classList.remove("modal-open");
    },

    methods: {
      close() {
        this.$emit("close");
      },

      submit() {
        this.$emit("submit");
      },
    },
  };
</script>

<style>
  body.modal-open {
    overflow: hidden;
  }
</style>

<style scoped lang="scss">
  .modal-mask,
  .modal-video-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    transition: opacity var(--transition-duration) ease;
  }
  .modal-container {
    --padding-side: 1.6rem;
    box-sizing: border-box;
    padding: 1.6rem 0;
    width: 350px;
    max-width: 100vw;
    min-height: 100px;
    max-height: 100vh;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: transform var(--transition-duration) ease;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $grey-900;
  }
  .modal-md {
    width: 500px;
  }

  .modal-lg {
    width: 700px;
  }

  .modal-xl {
    width: 1000px;
  }

  .modal-header {
    padding: 0 var(--padding-side);
    font-size: 18px;
    font-weight: 500;
    color: $grey-900;
    position: relative;
    text-align: left;
  }

  .modal-divider {
    margin: 12px 0;
    border-top: 1px solid $grey-300;
  }

  .modal-subtitle {
    font-size: 14px;
    font-weight: 400;
  }

  ::v-deep .modal-body {
    max-height: 100vh;
    flex: 1;
    padding: 0 var(--padding-side);

    .scrollbar {
      max-height: calc(100vh - 170px);
    }
  }

  .modal-footer {
    padding: 24px var(--padding-side) 0;
  }
  .modal-footer > * {
    margin: 0.5em;
  }
  .btn-close {
    box-sizing: border-box;
    font-size: 12px;
    padding: 12px;
    margin: 6px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    place-items: center;
    position: absolute;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background: none;
    color: $grey-800;
    z-index: 99;
  }
  .btn-close:hover {
    color: $grey-600;
  }
  .modal-video-container .btn-close {
    margin: 16px;
    color: $white;
    background: rgba(0, 0, 0, 0.41);
  }
  .modal-video-container {
    background: white;
    border-radius: 24px;
    padding: 8px;
    position: relative;
  }
  .modal-video {
    border-radius: 16px;
    max-width: 80vw;
    max-height: 80vh;
    margin: 0;
    display: block;
  }

  .modal-enter,
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      flex-grow: 1;
    }

    .btn-secondary {
      margin-right: 30px;
    }
  }

  @media (max-width: $xs) {
    .modal-container {
      --padding-side: 1rem;
      width: calc(100vw - 1rem);
    }

    .modal-video-container {
      width: 100%;
      background: none;
      padding: 0;
    }

    .modal-video {
      width: 100%;
      max-width: 100vw;
      max-height: 100vh;
    }
  }
</style>
